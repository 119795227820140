<template>
  <span class="edit-modal-tabs">
    <p v-if="PermissionManager.can(actions.can_see_editmodal_code)" class="control edit-mode-code">
      <button :class="`button ${editMode === 'code' ? 'is-active' : ''}`" :active="editMode === 'code'" @click="$emit('changeMode', 'code')">
        <i class="mdi mdi-code-braces" />&nbsp;
        {{i18n.code_view}}
      </button>
    </p>
    <p class="control edit-mode-table">
      <button :class="`button ${editMode === 'table' ? 'is-active' : ''}`" :active="editMode === 'table'" @click="$emit('changeMode', 'table')">
        <i class="mdi mdi-table" />&nbsp;
        {{i18n.form_view}}
      </button>
    </p>
    <template v-if="schema && schema.tabs">
      <p class="control" v-for="(t, i) in schema.tabs">
        <button :class="`button custom-tab-button ${editMode === `customTab_${i}` ? 'is-active' :
          ''} ${editedTab === i ? 'edited' : ''}`" :active="editMode === `customTab_${i}`" @click="$emit('changeMode', `customTab_${i}`)">
          <i :class="`mdi mdi-${t.icon || 'file'}`" />&nbsp;
          {{t.name || 'Nouvel onglet'}}
        </button>
      </p>
    </template>
    <p v-if="PermissionManager.can(actions.can_see_editmodal_metadata)" class="control edit-mode-metadata">
      <button :class="`button ${editMode === 'metadatas' ? 'is-active' : ''}`" :active="editMode === 'metadatas'" @click="$emit('changeMode', 'metadatas')">
        <i class="mdi mdi-key" />&nbsp;
        {{i18n.metadatas_view}}
      </button>
    </p>
    <p v-if="PermissionManager.can(actions.can_see_editmodal_history)" class="control edit-mode-history">
      <button :class="`button ${editMode === 'history' ? 'is-active' : ''}`" :active="editMode === 'history'" :disabled="!showHistory" @click="$emit('changeMode', 'history')">
        <i class="mdi mdi-history" />
        {{i18n.history_view}}
      </button>
    </p>

  </span>
</template>
<script>
import PermissionManager from '@/permissions/PermissionManager';
import actions from '@/permissions/actions';
import i18n from 'i18n/components/modals/EditModal.json';

export default {
  props: {
    schema: {
      type: Object,
      default: undefined,
    },
    editMode: {
      type: String,
      required: true,
    },
    editedTab: {
      type: String,
      default: undefined
    },
    showHistory: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      PermissionManager,
      actions,
      i18n,
    };
  }
};
</script>
<style scoped>
.edit-modal-tabs {
  display: flex;
}
.modal-modes-buttons .button.is-active {
  background: whitesmoke;
  padding-top: 8px;
  box-shadow: inset 0px 0px 5px 0px #77777755;
  border-color: #dbdbdb;
  border-bottom: 4px solid var(--primary-color);
}
</style>
